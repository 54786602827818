.header[data-v-f2633f63] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  z-index: 6;
  position: sticky;
  top: 0;
  box-shadow: var(--shadow-sm);
  background-color: var(--card-bg);
  margin-bottom: 5px;
  height: 48px;
}
