.layout[data-v-cb64a5ca] {
  overflow: hidden;
  display: flex;
  height: 100%;
  flex-direction: row;
  --mainView-sider: 30%;
}
.actions[data-v-cb64a5ca] {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  gap: 4px;
  float: inline-end;
}
.body[data-v-cb64a5ca] {
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-md);
  box-shadow: var(--card-shadow);
  background-color: var(--card-bg);
}
.toolbar[data-v-cb64a5ca] {
  position: relative;
  background-color: var(--card-bg);
  border-bottom: 1px solid var(--border-color);
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: var(--padding-sm);
  min-height: 40px;
  box-sizing: content-box;
  gap: 8px;
}
.toolbar[data-v-cb64a5ca]::after {
  content: '';
  clear: both;
}
.toolbox[data-v-cb64a5ca] {
  display: contents;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}
.toolbox > div[data-v-cb64a5ca] {
  display: contents;
}
.toolbox-close[data-v-cb64a5ca] {
  display: none;
}
.toolbox-btn[data-v-cb64a5ca] {
  display: none;
  margin-inline-end: auto;
}
@media screen and (max-width: 738px) {
.toolbox[data-v-cb64a5ca] {
    display: block;
    position: fixed;
    inset: 0;
    overflow: auto;
    background: rgba(0, 0, 0, 0.3);
    padding: 40px;
    padding-block-start: 80px;
    z-index: 1020;
}
.toolbox.hidden[data-v-cb64a5ca] {
    display: none;
}
.toolbox-btn[data-v-cb64a5ca] {
    display: inline-block;
}
.toolbox-close[data-v-cb64a5ca] {
    position: absolute;
    inset-block-start: -20px;
    inset-inline-end: -20px;
    display: block;
}
.toolbox > div[data-v-cb64a5ca] {
    display: block;
    padding: 20px;
    border-radius: 20px;
    position: relative;
    background-color: var(--card-bg);
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    min-height: 40px;
    box-sizing: content-box;
    gap: 8px;
}
}
.maindata[data-v-cb64a5ca] {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.context[data-v-cb64a5ca] {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.context .main[data-v-cb64a5ca] {
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  order: 1;
}
.context .pagination[data-v-cb64a5ca] {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.context .data[data-v-cb64a5ca] {
  overflow: auto;
  flex: 1;
  position: relative;
}
.context.skeleton-row[data-v-cb64a5ca] {
  flex-direction: row;
}
